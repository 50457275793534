import { gql, useMutation } from 'urql';

import { limitBlockingFragment } from '@/fragments/Limits';
import {
  BlockSessionLimit,
  BlockSessionLimitVariables,
} from './__generated__/BlockSessionLimit';
import {
  UnblockSessionLimit,
  UnblockSessionLimitVariables,
} from './__generated__/UnblockSessionLimit';

const BLOCK_SESSION_LIMIT = gql`
  mutation BlockSessionLimit($playerId: ID!) {
    blockSessionLimit(playerId: $playerId) {
      id
      sessionLimit {
        ...LimitBlockingFragment
      }
    }
  }
  ${limitBlockingFragment}
`;

const UNBLOCK_SESSION_LIMIT = gql`
  mutation UnblockSessionLimit($playerId: ID!) {
    unblockSessionLimit(playerId: $playerId) {
      id
      sessionLimit {
        ...LimitBlockingFragment
      }
    }
  }
  ${limitBlockingFragment}
`;

export const useBlockSessionLimit = () => {
  return useMutation<BlockSessionLimit, BlockSessionLimitVariables>(
    BLOCK_SESSION_LIMIT,
  );
};

export const useUnblockSessionLimit = () => {
  return useMutation<UnblockSessionLimit, UnblockSessionLimitVariables>(
    UNBLOCK_SESSION_LIMIT,
  );
};
